$beige : #FEF8F4;
$saumon : #F35E50;

$bExb: 'berninasansoffc-exb', -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$bExbIt: 'berninasansoffc-exbit', -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$hLt: 'helvetica-light', "Helvetica Neue", sans-serif;
$h: 'helvetica', "Helvetica Neue", sans-serif;
$kobe: "Kobe", "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
"Helvetica Neue", sans-serif;

#NotFound{
  padding: 0 50px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon{
    height: 80px;
    @media (max-width:375px){
      height: 50px;
    }
  }
  h1{
    color: $saumon;
    margin-bottom: 0;
    margin-top: 15px;
  }
  h2{
    color: $beige;
    -webkit-text-stroke: 0.5px;
    -webkit-text-stroke-color: $saumon; 
    margin-bottom: 25px;
  }
  button{
    background: transparent;
    border: 1px solid $saumon;
    color: $saumon;
    text-align: center;
    padding: 13px;
    width: 230px;
    border-radius: 20px;
    outline: none;
    cursor: grab;
  }
  button:hover{
    background: $saumon;
    border: 1px solid $beige;
    color: $beige;
  }
}