$beige: #fef8f4;
$saumon: #f35e50;

$bExb: "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", sans-serif;
$bExbIt: "berninasansoffc-exbit", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", sans-serif;
$hLt: "helvetica-light", "Helvetica Neue", sans-serif;
$h: "helvetica", "Helvetica Neue", sans-serif;
$kobe: "Kobe", "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
"Helvetica Neue", sans-serif;

#Homepage {
  padding: 0 50px;
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 80vh;
    .Home-left {
      padding-right: 0px;
      // margin: 40px 0;
      .icon {
        height: 80px;
        @media (max-width: 375px) {
          height: 50px;
        }
      }
      ul {
        margin: 20px 0 50px 0;
        list-style-type: none;
        padding-inline-start: 0;

        color: $saumon;
        :hover {
          color: $beige;
          font-family: $kobe;
          -webkit-text-stroke: 0.5px;
          -webkit-text-stroke-color: $saumon;
        }
        li {
          h1 {
            margin-bottom: 0;
            padding: 5px;
          }
        }

        a {
          color: $saumon;
          :hover {
            color: $beige;
            font-family: $kobe;
            -webkit-text-stroke: 0.5px;
            -webkit-text-stroke-color: $saumon;
          }
          li {
            h1 {
              margin-bottom: 0;
              padding: 5px;
            }
          }
        }
      }
      @media (max-width: 375px) {
        padding: 0;
      }
    }
    .Home-right {
      display: flex;
      align-items: center;
      justify-content: center;
      .bg {
        padding: 50px;
        width: 70%;
        min-width: 450px;
        max-width: 545px;
        object-fit: contain;
        @media (max-width: 991px) {
          width: 100%;
        }
        @media (max-width: 540px) {
          min-width: 300px;
        }
      }
      .bg-tattoo {
        padding: 50px;
        width: auto;
        height: 520px;
        min-width: 450px;
        min-height: auto;
        object-fit: contain;
        @media (max-width: 540px) {
          min-width: 400px;
          height: auto;
        }
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    @media (max-width: 767px) {
      align-items: center;
    }
  }
}
