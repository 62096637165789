// COLORS
// ----------------------------------------------------------------

$beige : #FEF8F4;
$saumon : #F35E50;

// FONTS
// ----------------------------------------------------------------

@font-face {
  font-family: 'berninasansoffc-exb';
  src: url('../../font/berninasansoffc-exb.ttf') format('truetype');
}
@font-face {
  font-family: 'berninasansoffc-exbit';
  src: url('../../font/berninasansoffc-exbit.ttf') format('truetype');
}
@font-face {
  font-family: 'helvetica-light';
  src: url('../../font/helvetica-light.ttf') format('truetype');
}
@font-face {
  font-family: 'helvetica';
  src: url('../../font/helvetica.ttf') format('truetype');
}
@font-face {
  font-family: 'Kobe';
  src: url('../../font/Kobe.ttf') format('truetype');
}

$bExb: 'berninasansoffc-exb', -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$bExbIt: 'berninasansoffc-exbit', -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$hLt: 'helvetica-light', "Helvetica Neue", sans-serif;
$h: 'helvetica', "Helvetica Neue", sans-serif;
$kobe: "Kobe", "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
"Helvetica Neue", sans-serif;

// Big title
h1{
  font-size: 64px;
  font-family: $kobe;
  line-height: 45px;
  @media (max-width:960px){
    font-size: 54px;
    line-height: 40px;
  }
  @media (max-width:720px){
    font-size: 50px;
    line-height: 37px;
  }
  @media (max-width:540px){
    font-size: 46px;
    line-height: 35px;
  }
}

// Medium titles
h2{
  font-size: 40px;
  font-family: $kobe;
  line-height: 37px;
  @media (max-width:960px){
    font-size: 42px;
  }
  @media (max-width:720px){
    font-size: 40px;
  }
  @media (max-width:540px){
    font-size: 38px;
  }
}

// Little titles
h3{
  font-family: $kobe;
  font-size: 34px;
}

// Paragraphs (inside place details pages) or Subtitles (inside gallery of places)
p{
  font-family: $hLt;
  font-size: 12px;
  color: #F35E50;
  letter-spacing: 0.5px;
}

// Mono
.span{
  font-family: $hLt;
  font-size: 8px;
  letter-spacing: 5.6px;
  text-transform: uppercase;
  line-height: 13px;
}


// LINKS
// ----------------------------------------------------------------

a:hover{
  text-decoration: none;
  
}