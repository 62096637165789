$beige: #fef8f4;
$saumon: #f35e50;

$bExb: "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", sans-serif;
$bExbIt: "berninasansoffc-exbit", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", sans-serif;
$hLt: "helvetica-light", "Helvetica Neue", sans-serif;
$h: "helvetica", "Helvetica Neue", sans-serif;
$kobe: "Kobe", "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
"Helvetica Neue", sans-serif;

#MenuOpen {
  position: absolute;
  width: 100%;
  z-index: 10000;
  .menuop {
    padding: 40px 50px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $saumon;
    .contact {
      display: flex;
      flex-direction: row;
      align-items: center;
      .buttons {
        display: flex;
        flex-direction: column;
        button {
          margin: 5px 0;
          width: 220px;
          height: 40px;
          border: 1px solid $beige;
          color: $beige;
          background-color: transparent;
          border-radius: 20px;
          outline: none;
          cursor: grab;
          @media (max-width: 375px) {
            width: 180px;
          }
          -webkit-transition-duration: 0.5s;
          -webkit-transition-timing-function: linear;
        }
        button:hover {
          border: 1px solid $saumon;
          color: $saumon;
          -webkit-box-shadow: 230px 0 0 $beige inset;
        }
      }
      .content {
        margin: 0 40px;
        h2 {
          color: $beige;
        }
        span {
          color: $saumon;
          font-family: $kobe;
          -webkit-text-stroke: 0.5px;
          -webkit-text-stroke-color: $beige;
        }
        @media (max-width: 540px) {
          margin: 20px 0 0 0;
        }
      }
      @media (max-width: 540px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .menu {
      height: 40px;
      @media (max-width: 450px) {
        margin-right: -10px;
      }
      .menu-img {
        height: 100%;
        margin-right: -3.5px;
        width: auto;
        cursor: grab;
      }
    }
  }
}
