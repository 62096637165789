$beige: #fef8f4;
$saumon: #f35e50;

$bExb: "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", sans-serif;
$bExbIt: "berninasansoffc-exbit", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", sans-serif;
$hLt: "helvetica-light", "Helvetica Neue", sans-serif;
$h: "helvetica", "Helvetica Neue", sans-serif;
$kobe: "Kobe", "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
"Helvetica Neue", sans-serif;

#ProjectDetails {
  padding: 0 50px;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    padding: 0 40px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    height: 80vh;
    .right-side {
      @media (min-width: 767px) {
        padding-bottom: 50px;
      }
    }
    .left-side,
    .right-side {
      @media (max-width: 767px) {
        padding: 0;
      }
    }
    .left-side {
      @media (max-width: 575px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h1,
      h2,
      p {
        width: 100%;
        text-align: left;
      }
      h1 {
        color: $saumon;
        margin-bottom: 0;
        margin-top: 15px;
      }
      h2 {
        color: $beige;
        -webkit-text-stroke: 0.5px;
        -webkit-text-stroke-color: $saumon;
        margin-bottom: 15px;
      }
      .miniature {
        width: 100%;
        height: 250px;
        object-fit: cover;
        @media (max-width: 991px) {
          height: 350px;
        }
        @media (max-width: 767px) {
          height: 300px;
        }
        @media (max-width: 575px) {
          height: 250px;
        }
      }
      button {
        background: transparent;
        border: 1px solid $saumon;
        color: $saumon;
        text-align: center;
        padding: 13px;
        width: 230px;
        border-radius: 20px;
        outline: none;
        //   cursor: grab;
        -webkit-transition-duration: 0.5s;
        -webkit-transition-timing-function: linear;
      }
      button:hover {
        border: 1px solid $beige;
        color: $beige;
        -webkit-box-shadow: 230px 0 0 $saumon inset;
      }
    }
    .right-side {
      .biggest {
        width: 100%;
        height: 80%;
        object-fit: contain;
        @media (min-width: 991px) {
          max-height: 520px;
        }
      }
      .biggestapp {
        width: 100%;
        height: 80%;
        object-fit: contain;
        max-height: 520px;
      }
      div {
        ul {
          padding-inline-start: 0;
          li {
            list-style-type: none;
          }
        }
        @media (max-width: 991px) {
          margin: 30px 0;
        }
        .arrows {
          z-index: 9999;
          margin-top: 20px;
          .arrow {
            margin: 0 10px;
          }
          @media (max-width: 575px) {
            display: flex;
            justify-content: center;
          }
        }
      }
      .arrows {
        z-index: 9999;
        margin-top: 20px;
        .arrow {
          margin: 0 10px;
          cursor: pointer;
        }
      }
      @media (max-width: 991px) {
        margin-top: 50px;
      }
    }
  }
  .icondiv {
    position: fixed;
    bottom: 50px;
    text-align: left;
    .icon {
      height: 50px;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
}
