$beige: #fef8f4;
$saumon: #f35e50;

$bExb: "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", sans-serif;
$bExbIt: "berninasansoffc-exbit", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", sans-serif;
$hLt: "helvetica-light", "Helvetica Neue", sans-serif;
$h: "helvetica", "Helvetica Neue", sans-serif;
$kobe: "Kobe", "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
"Helvetica Neue", sans-serif;

#Header {
  padding: 0 50px;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a,
  .menu {
    height: 40px;
    .logo {
      height: 100%;
      width: auto;
    }
    .menu-img {
      height: 80%;
      width: auto;
      cursor: grab;
    }
  }
  @media (max-width: 450px) {
    padding: 0 40px;
  }
}
