$beige: #fef8f4;
$saumon: #f35e50;

$bExb: "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", sans-serif;
$bExbIt: "berninasansoffc-exbit", -apple-system, BlinkMacSystemFont, Roboto,
  "Helvetica Neue", sans-serif;
$hLt: "helvetica-light", "Helvetica Neue", sans-serif;
$h: "helvetica", "Helvetica Neue", sans-serif;
$kobe: "Kobe", "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
"Helvetica Neue", sans-serif;

#About {
  padding: 0 50px;
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .About-left {
      padding: 0 50px 0 0;
      .miniature {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        min-width: 230px;
      }
      .contact {
        .sm-links {
          height: 80px;
        }
        button {
          background: transparent;
          border: 1px solid $saumon;
          color: $saumon;
          text-align: center;
          padding: 13px;
          width: 230px;
          border-radius: 20px;
          outline: none;
          cursor: grab;
          -webkit-transition-duration: 0.5s;
          -webkit-transition-timing-function: linear;
        }
        button:hover {
          border: 1px solid $beige;
          color: $beige;
          -webkit-box-shadow: 230px 0 0 $saumon inset;
        }
        .all-rs {
          .rs {
            height: 40px;
            margin: 20px 10px;
          }
        }
      }
      @media (max-width: 767px) {
        padding: 0;
        margin-bottom: 50px;
      }
      @media (max-width: 575px) {
        padding: 0;
      }
    }
    .About-right {
      padding: 0 50px;
      h1 {
        color: $saumon;
        margin: 15px 0;
      }
      h2 {
        color: $beige;
        -webkit-text-stroke: 0.5px;
        -webkit-text-stroke-color: $saumon;
      }
      @media (max-width: 991px) {
        padding: 0 50px;
      }
      @media (max-width: 767px) {
        padding: 0;
        margin-bottom: 50px;
      }
    }
    @media (max-width: 767px) {
      flex-wrap: wrap-reverse;
      height: auto;
    }
  }
  .icondiv {
    position: fixed;
    bottom: 50px;
    right: 50px;
    text-align: right;
    .icon {
      height: 50px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
}
