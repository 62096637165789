$beige : #FEF8F4;
$saumon : #F35E50;

$bExb: 'berninasansoffc-exb', -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$bExbIt: 'berninasansoffc-exbit', -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$hLt: 'helvetica-light', "Helvetica Neue", sans-serif;
$h: 'helvetica', "Helvetica Neue", sans-serif;
$kobe: "Kobe", "berninasansoffc-exb", -apple-system, BlinkMacSystemFont, Roboto,
"Helvetica Neue", sans-serif;

#ProjectList{
  padding: 0 50px;
  @media (max-width:767px){
    padding: 0 40px;
  }
  ul{
    display: flex;
    flex-wrap: wrap;  
    padding-inline-start: 0;
    padding: 0;
    li{
      list-style-type: none;
      padding: 0 25px;
      text-align: center;
      margin-bottom: 20px;
      // height: 100%;
      // margin-bottom: 20px;
      @media (max-width:767px){
        padding: 0;
      }
      a{
        .projectpic{
          width: 100%;
          height: 70%;
          object-fit: cover;
          @media(min-width:991px){
            max-height: 250px;
          }
        }
        h2{
          margin-top: 10px;
          color : $saumon;
        }
        p{
          margin-bottom: 0;
        }
      }
    }
  }
  .icondiv{
    position: fixed;
    bottom: 50px;
    left: 50px;
    text-align: left;
    .icon{
      height: 50px;
    }
    @media (max-width: 767px){
      display: none;  
    }
  }
}